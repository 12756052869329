import dayjs from "dayjs";
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import storageService from "../Services/storageService";
import { shouldSendEvent } from "./utils";
import ReactPixel from "react-facebook-pixel";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

if (typeof window?.fbq === "undefined") {
  ReactPixel.init("429545679512361", options);
}

// ReactPixel.grantConsent();

mixpanel.init("b753c4210460ed71e24176f7a61108a4");

export const pixelPageView = () => {
  ReactPixel.pageView();
  const isLive = true; // storageService.get("isGHC");
  const obj = {
    content_type: "home_listing",
    content_ids: [storageService.get("hotelId")],
    city: storageService.get("city"),
    region: storageService.get("state"),
    country: storageService.get("country"),
  };
  shouldSendEvent && isLive && ReactPixel.track("ViewContent", obj);
};

const tagManagerArgs = {
  gtmId: "GTM-KGP56JZ",
};
if (shouldSendEvent) {
  TagManager.initialize(tagManagerArgs);
}

export const fireGtmEvent = (name, obj = {}) => {
  // console.log("this called when ?");

  let isLive = true;
  let obj2 = {
    themeId: 2,
    hotelName: storageService.get("hotelName"),
    userId: storageService.get("userId"),
    hotelId: storageService.get("hotelId"),
    searchParams: storageService.get("searchParams"),
    slug: storageService.get("slug"),
    source: storageService.get("source") || "Hotelzify_Fallback",
    domain: storageService.get("domain"),
    end_date: storageService.get("checkOutDate"),
    start_date: storageService.get("checkInDate"),
    currentDate: dayjs().format("YYYY/MM/DD"),
    url: window.location.href,
    locationDetails: storageService.get("locationDetails") || {},
    ghcEnabled: isLive,
    content_type: "home_listing",
    content_ids: [storageService.get("hotelId")],
    city: storageService.get("city"),
    region: storageService.get("state"),
    country: storageService.get("country"),
    ...obj,
  };

  const isDev =
    window.location.host.includes("localhost") ||
    window.location.host.includes("dev.hotelzify");

  if (!isDev) {
    window &&
      window?.dataLayer?.push({
        event: name,
        ...obj2,
      });
    try {
      ReactPixel.trackCustom(name, obj2);
      mixpanel.track(name, obj2);
    } catch (error) {
      console.log("mixpanel err", error);
    }
  }
};

export const mixpanelConversion = (obj) => {
  let isLive = storageService.get("isGHC");
  let obj2 = {
    themeId: 2,
    hotelName: storageService.get("hotelName"),
    userId: storageService.get("userId"),
    hotelId: storageService.get("hotelId"),
    slug: storageService.get("slug"),
    source: storageService.get("source") || "Hotelzify_Fallback",
    domain: storageService.get("domain"),
    end_date: storageService.get("checkOutDate"),
    start_date: storageService.get("checkInDate"),
    currentDate: dayjs().format("YYYY/MM/DD"),
    searchParams: storageService.get("searchParams"),
    url: window.location.href,
    testId: storageService.get("testId"),
    locationDetails: storageService.get("locationDetails") || {},
    content_type: "home_listing",
    content_ids: [storageService.get("hotelId")],
    city: storageService.get("city"),
    region: storageService.get("state"),
    country: storageService.get("country"),
    ...obj,
  };
  try {
    shouldSendEvent && isLive && mixpanel.track("conversion", obj2);
  } catch (error) {
    console.log("mixpanel err", error);
  }
};

export const pixelConversion = (obj) => {
  let isLive = storageService.get("isGHC");

  let obj2 = {
    themeId: 2,
    searchParams: storageService.get("searchParams"),
    hotelName: storageService.get("hotelName"),
    userId: storageService.get("userId"),
    hotelId: storageService.get("hotelId"),
    slug: storageService.get("slug"),
    source: storageService.get("source") || "Hotelzify_Fallback",
    domain: storageService.get("domain"),
    end_date: storageService.get("checkOutDate"),
    start_date: storageService.get("checkInDate"),
    currentDate: dayjs().format("YYYY/MM/DD"),
    url: window.location.href,
    locationDetails: storageService.get("locationDetails") || {},
    content_type: "home_listing",
    content_ids: [storageService.get("hotelId")],
    city: storageService.get("city"),
    region: storageService.get("state"),
    country: storageService.get("country"),
    ...obj,
  };

  shouldSendEvent && isLive && ReactPixel.track("Purchase", obj2);
};

export const pixelHighIntent = (name) => {
  let isLive = storageService.get("isGHC");

  let obj2 = {
    themeId: 2,
    hotelName: storageService.get("hotelName"),
    userId: storageService.get("userId"),
    hotelId: storageService.get("hotelId"),
    searchParams: storageService.get("searchParams"),
    slug: storageService.get("slug"),
    source: storageService.get("source") || "Hotelzify_Fallback",
    domain: storageService.get("domain"),
    end_date: storageService.get("checkOutDate"),
    start_date: storageService.get("checkInDate"),
    currentDate: dayjs().format("YYYY/MM/DD"),
    url: window.location.href,
    locationDetails: storageService.get("locationDetails") || {},
    content_type: "home_listing",
    content_ids: [storageService.get("hotelId")],
    city: storageService.get("city"),
    region: storageService.get("state"),
    country: storageService.get("country"),
  };

  shouldSendEvent && isLive && ReactPixel.track(name, obj2);
};

export const newFBEvents = (name, obj) => {
  let obj2 = {
    content_type: "hotel",
    checkin_date: storageService.get("checkInDate"),
    checkout_date: storageService.get("checkOutDate"),
    content_ids: String(storageService.get("hotelId")),
    city: storageService.get("city") || "Bengaluru",
    region: storageService.get("state") || "Karnataka",
    country: storageService.get("country") || "India",
    ...obj,
  };

  const isDev =
    window.location.host.includes("localhost") ||
    window.location.host.includes("dev.hotelzify");

  if (isDev) {
    return;
  }
  ReactPixel.track(name, obj2);
};
